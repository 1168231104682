.BoardTest {
  height: calc(100dvh - 60px);
  background-color: #ffffff;
}

.ListContainerTest {
  height: calc(100dvh - 60px - 50px);
  background-color: transparent;
}

.ListTest {
  max-height: calc(100dvh - 60px - 50px);
  min-height: 100px;
  max-width: 250px;
  min-width: 250px;
  color: #161a30;
  overflow-x: hidden;
}

.TaskTest {
  max-width: 270px;
}

.EditBtn {
  color: #161a30;
}

.List:hover .EditBtn {
  color: white;
}

.AddCard {
  background-color: transparent;
  color: #003366;
}

.AddCard:hover {
  background-color: #003366;
  color: white;
}

.Task:hover {
  border: 1px solid #3ac7ff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 4px #3ac7ff;
  -moz-box-shadow: 0px 0px 4px #3ac7ff;
  box-shadow: 0px 0px 4px #3ac7ff;
  outline: none;
}

.DeleteBtn {
  color: transparent;
}

.Task:hover .DeleteBtn {
  color: red;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
  width: 5px;
}
::-webkit-scrollbar:horizontal {
  height: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #161a3000; /* should match background, can't be transparent */
  background-color: #b6bbc4;
}
::-webkit-scrollbar-track {
  background-color: #161a3000;
  border-radius: 8px;
}

.form-control:hover,
.form-control:active,
.form-control:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.error {
  font-size: 11px;
  margin: 0;
  padding: 0;
}

.DrawerInput {
  width: 100%;
  margin-bottom: 3px;
  background-color: rgb(250, 251, 252);
  border-color: rgb(223, 225, 230);
  color: rgb(9, 30, 66);
  cursor: text;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  font-size: 14px;
  transition: background-color 0.2s ease-in-out 0s,
    border-color 0.2s ease-in-out 0s;
  line-height: 1.42857;
  padding: 8px 6px;
  outline: none;
}

.DrawerInput:hover {
  background-color: rgb(235, 236, 240);
}

.DrawerInput:focus {
  background-color: rgb(255, 255, 255);
  border-color: rgb(76, 154, 255);
}
