body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error-msg {
  color: red;
  font-size: 12px !important;
}
.btn-dark:focus{
  box-shadow: none !important;
}
.btn:focus {
  box-shadow: none !important;
}
.btn-close:focus{
box-shadow: none !important;
}
.form-control {
  font-size: 14px !important;
}
.required_field{
  color: red;
}
.resource_field {
  font-size: 12px !important;
}

/* media query */
@media (min-width: 320px) and (max-width: 749px) {
  .dropdown-menu-end[data-bs-popper] {
    left: 0 !important;
    right: 0 !important;
  }
  /* .welcome_board {
    display: flex !important;
  } */
}
@media (min-width: 750px) and (max-width: 820px){
  /* .welcome_board {
    display: flex !important;
  } */
  .dropdown-menu-end[data-bs-popper] {
    left: 0 !important;
    right: 0 !important;
  }
}
@media (min-width: 900px) and (max-width: 912px){
  /* .welcome_board {
    display: flex !important;
  } */
}
@media (min-width: 1275px) and (max-width: 2080px){
  /* .welcome_board {
    display: flex !important;
  } */
  .user_menu {
    margin-left: auto !important;
  }
}
/* Set some basic styling for the board */
.kanban-board {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

/* Styling for the columns container */
.column-container {
  display: flex;
}

/* Styling for the columns */
.column {
  flex: 1;
  background-color: #e2e4e6;
  border-radius: 5px;
  padding: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.column h2 {
  font-size: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.column-title-editable {
  background-color: #f9f9f9;
}

/* Styling for the task list when a task is being dragged over */
.task-list.dragging-over {
  background-color: #f7f7f7;
  border: 2px dashed #ccc;
}

/* Styling for the task cards */
.task-card {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.task-card h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

/* Styling for the "Add Column" button */
.board-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.board-header button {
  background-color: #61bd4f;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
}

.board-header button:hover {
  background-color: #5aac44;
}

/* Styling for the task input */
.task-input {
  margin-top: 10px;
  display: flex;
}

.task-input input {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.task-input button {
  background-color: #0079bf;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
}

.task-input button:hover {
  background-color: #005a9e;
}
.task-list.dragging-over {
  background-color: #f7f7f7;
  border: 2px dashed #ccc;
}

/* Styling for the empty droppable area placeholder */
.empty-placeholder {
  text-align: center;
  color: #777;
  margin-top: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f9f9f9;
}

.column h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

/* Styling for the task cards */
.task-card {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.task-card h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

/* Styling for the "Add Column" button */
.board-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.board-header button {
  background-color: #61bd4f;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  margin-left: auto;
}

.board-header button:hover {
  background-color: #5aac44;
}

/* Styling for the "Add Task" button */
.task-input {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.task-input input {
  flex: 1;
  border: 1px solid #ccc;
}

.title_card span {
  font-size: 18px;
  font-weight: 500;
}

.avatar_comments {
  line-height: 34px;
  padding-left: 7px;
  margin-right : 10px
}
.btn-width {
  width: 100%;
  text-align: left;
  margin: 10px 0;
}
#projectconfig .project, 
#orcateconfig .orcate, 
#sailorconfig .sailor {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 5%;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  /* padding-top: 20px; */
}


.custom-tooltip .tooltip-inner{
  background-color: #333;
  color: white;
  border: 1px solid #666;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
}