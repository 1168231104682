.Listview {
  height: calc(100dvh - 60px);
  background-color: #ffffff;
}

.accordion-button:active,
.accordion-button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.accordion-item {
  opacity: 1 !important;
}
