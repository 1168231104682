.Board {
  height: calc(100dvh - 175px);
  background-color: #ffffff;
}

.List {
  min-height: 100px;
  max-width: 350px;
  min-width: 350px;
  color: #161a30;
  background-color: #f1f0e8;
}

.ListContainer {
  background-color: transparent;
}

.Task {
  background: #da22ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #9733ee,
    #da22ff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #9733ee,
    #da22ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 300px;
  color: white;
}

/* Gantt View */

/* Hide scrollbar for Chrome, Safari and Opera */
.TableGantt::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.TableGantt {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
